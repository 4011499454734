<template>
  <div>
    <layout-header :active-menu="activeMenu" />
    <div class="m_layout" >
      <div class="m-content-box" >
          <router-view ></router-view>
      </div>
    </div>
    <div class="p_layout" >
      <layout-sidebar :collapse="collapse" :is-active-menu="activeMenu"/>
      <div class="p-content-box" :class="{ 'content-collapse': collapse }">
        <div class="content">
          <router-view ></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import LayoutHeader from "../components/LayoutHeader.vue";
import LayoutSidebar from "../components/LayoutSidebar.vue";


export default {
  components: {LayoutHeader, LayoutSidebar},
  data() {
    return {
      isMobile: false,
      activeMenu: "",
      onRoutes: ""
    }
  },
  computed: {
    tagsList() {
      return this.$store.state.tagsList
    },
    collapse() {
      return this.$store.state.collapse
    }
  },
  watch: {
    $route(to) {
      this.activeMenu = this.$route.meta.activePath ? this.$route.meta.activePath : this.$route.fullPath
      const isExist = this.tagsList.some((item) => {
        return item.path === to.fullPath;
      });
      if (!isExist) {
        if (this.tagsList.length >= 8) {
          this.$store.commit("delTagsItem", {index: 0});
        }
        this.$store.commit("setTagsItem", {
          name: to.name,
          title: to.meta.title,
          path: to.fullPath,
        });
      }

    },

  },
  methods: {},
  mounted() {
    if (document.documentElement.clientWidth <= 750) {
      this.isMobile = true
    }
    this.activeMenu = this.$route.meta.activePath ? this.$route.meta.activePath : this.$route.fullPath
  }

};
</script>
<style lang="less">
.p-content-box {
  position: absolute;
  left: 176px;
  right: 0;
  top: 70px;
  bottom: 0;
  padding-bottom: 30px;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out;
  background: #f0f0f0;

  &.content-collapse {
    left: 65px !important;
  }

  .content {
    width: auto;
    height: 100%;
    padding: 15px;
    overflow-y: scroll;
    box-sizing: border-box;
  }
}
.m-content-box {
  position: absolute;
  left: 0px;
  right: 0;
  top: 6.4rem;
  bottom: 0;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out;
  background: #F6F7FF;
  overflow-x: hidden;
}

</style>
